
import { Box, Button, styled, Typography, SvgIcon } from "@mui/material";
import { GC_BUCKET_URL, ORDER_STATUS } from "../utils/constant";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../Context";
import { Link } from "react-router-dom";
import { audioSVG, videoSVG, startSVG, resaleSVG } from "./SVG";

const MyButton = styled(Button)({
  backgroundColor: "#6FDA44",
  color: "#2A3538",
  borderRadius: "4px",
  width: "100%",
  fontFamily: "'Montserrat', sans-serif",
  "&:hover": {
    backgroundColor: "#90E26F",
  },
  fontSize: "14px",
  fontWeight: "700",
  flex: 1,
});

const NftItemCard = ({ nft, list, buy }) => {
  
  const { isMobile } = useContext(AppContext);
  const fileType = nft.fileType.toLowerCase()  
  
  return (
    <Box position="relative">
      <Link
        to={`/nft/${nft.id}`}
        style={{
          textDecoration: "none",
          color: "black",
        }}
      >
        <Box
          position="absolute"          
          left={5}
          top={5}
          zIndex={10}
          sx={{
            background: "white",            
            opacity: 0.6,
            padding: "6px",
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <SvgIcon>
            {fileType === 'audio' && audioSVG()}
            {fileType === 'video' && videoSVG()}
          </SvgIcon>
        </Box>

        <img 
          src={`${GC_BUCKET_URL}${nft?.image}`} 
          alt="nft" 
          style={{ 
            borderRadius: "8px",
            width: "100%", 
            height: isMobile ? "160px" : "250px", 
            }} />

        <Box pt={{ xs: "6px", md: "10px" }}>
          <Typography
            fontSize={{
              xs: "14px",
              md: "20px",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
            textAlign={{
              xs: "center",
              md: "left",
            }}
            fontWeight={"700"}
            color={"#2A3538"}
          >
            {nft?.title}
          </Typography>
          <Box mt="6px" display="flex" alignItems={"center"} justifyContent={{ xs: "center", md: "flex-start" }}>            
            <Typography color={"#2A3538"} fontSize={{ xs: "12px", md: "16px" }} fontWeight={"700"}>
              {nft?.price}
            </Typography>
            <Typography color={"#2A3538"} fontSize={{ xs: "12px", md: "14px" }} ml={1}>
              USDT
            </Typography>
          </Box>
          
        </Box>
      </Link>
      
    </Box>
  );
};

export default NftItemCard;
