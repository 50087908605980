/* eslint-disable camelcase */
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import {  
  Avatar,
  Box,
  Button,
  Container,  
  Grid,  
  IconButton,
  Stack,
  SvgIcon,  
  Typography,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../Context";
import Ticon from "../assets/ticon-circle.png";
import NftItemCard from "src/components/NftItemCard";
import MediaItemCard from "src/components/MediaItemCard";
import { ContentCopy, DoneAll } from "@mui/icons-material";

import useNft from "../hooks/useNFT";
import { useSaleNfts } from "src/hooks";
import { GC_BUCKET_URL, FILE_TYPE } from "../utils/constant";
import * as moment from 'moment';
import { audioSVG, shareSVG, videoSVG, startSVG, resaleSVG, favoriteSVG, calendarSVG, numberSignSVG } from "../components/SVG";
import ConnectionModal from "src/components/Modal/ConnectModal";

const StyledLink = styled(Typography)({
  fontWeight: 700,
  transition: "0.4s",
  cursor: "pointer",
  borderBottom: "none !important",
  textDecoration: "none !important",
});

const Social = styled(Button)({
  background: "#fff",  
  border: "1px solid #666",
  borderRadius: " 10px",
  padding: "8px 8px", 
  margin: "0 12px" 
});

const LoadMore = styled(Button)({
  background: "#2A3538",
  color: "white",
  borderRadius: " 6px",
  padding: "4px 16px", 
  width: "200px",
  ":hover": {
    backgroundColor: "#2A3538"
  }
});


const ViewContent = () => {
  const params = useParams();
  const videoRef = useRef();  
  const navigate = useNavigate();    

  const [pagination, setPagination] = useState({
    page: 1,
    size: 4,    
  })  
  const [user, setUser] = useState()
  const { wallet, connectModal, setAlertState, toggleConnectModal } = useContext(AppContext);  
  const {nft, execute, isLoading} = useNft(params?.id);  
  const {saleNfts, hasMore} = useSaleNfts(pagination, nft?.walletId)

  const breadCrumbs = [
    {label: 'Home', url: '/'},
    {label: 'Shop', url: '/shop'},
    {label: nft?.title, url: `/nft/${nft?.id}`},
    {label: 'View Content', active: true},
  ]
  
  useEffect(() => {
    videoRef.current?.load();
    if (nft.wallet?.userWallets?.length) {
      setUser(nft.wallet.userWallets[0].user)
    }
  }, [nft]);

  const handleCopy = (address) => {    
    navigator.clipboard.writeText(address);

    setAlertState({
      open: true,
      message: "Copied!",
      severity: "info",
    });
  };  

  const handleLoadMore = () => {
    setPagination({...pagination, page: pagination.page + 1})
  };    

  // console.log(nft)
  const BreadCrumb = () => (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      backgroundColor="#fff"
      style={{
        zIndex: "1000px",
      }}
      height="80px"
      width="100%"
    >
      <Container maxWidth="lg">        
        <Stack direction="row" justifyContent="cneter" alignItems="center">
          {breadCrumbs.map((it, idx) => <Stack direction='row' alignItems='center'>
            <Link to={it.url}>
              <Typography 
                mx={{ md: 3, xs: 1 }} 
                py={2}
                fontSize={{ md: "16px", xs: "12px"}} 
                sx={{ fontWeight: it.active ? 600 : 400, color: 'black' }}>
                {it.label} 
              </Typography>
            </Link>
            {idx !== breadCrumbs.length - 1 && <ArrowForwardIosIcon sx={{width: '16px'}}/>}
          </Stack>
        )}
        </Stack>
      </Container>
    </Box>
    
  );

  return (
    <>
      <BreadCrumb />
      <Box>        
        <><ConnectionModal connectModal={connectModal} toggleConnectModal={toggleConnectModal} />            
            <Box sx={{ backgroundColor: "#fff" }}>
              <Container maxWidth="lg">
                <Grid container>
                  <Grid item md={12} xs={12}>                
                    <MediaItemCard nft={nft}/>
                  </Grid>                  
                </Grid>
                
                <Grid container mt={2}>
                  <Grid item md={9} xs={12}>
                    <Stack alignItems="center" direction="row">
                      <Typography variant="h1">{nft.title}</Typography>
                      <Stack alignItems="center" direction="row" ml="auto">
                        <img src={Ticon} width="18px" alt="seen icon" />
                        <Typography variant="subtitle1" ml="6px">{`${nft.price} USDT`}</Typography>
                      </Stack>
                      
                    </Stack>

                    <Stack alignItems="center" direction="row" spacing={1} mt={2}>
                      <Stack direction='row' alignItems='center' sx={{border: '1px solid #6FDA44', borderRadius: '8px'}} p={1}>
                        <SvgIcon sx={{width: '12px'}}>{nft.fileType === 'audio' ? audioSVG(): videoSVG()}</SvgIcon>
                        <Typography variant="body2" ml={1} sx={{textTransform: 'capitalize'}}>{nft.fileType}</Typography>
                      </Stack>
                      <Box sx={{border: '1px solid #6FDA44', borderRadius: '8px'}} p={1}>
                        <Typography variant="body2"># {nft.id}</Typography>                        
                      </Box>
                    </Stack>

                    <Typography variant="subtitle1" mt={6}>Description</Typography>

                    <Typography mt={1} variant="body2">{nft.description}</Typography>

                    <Stack mt={8} alignItems="center" direction="row">
                      <Stack alignItems="center" direction="row">
                      <Avatar
                        alt="Remy Sharp"
                        src={`${GC_BUCKET_URL}${nft?.creator?.photo}`}
                        sx={{
                          width: 50,
                          height: 50,
                        }}                        
                      />  
                      <Box ml={1}>
                        <Typography variant="subtitle1">Creator</Typography>
                        <Typography variant="body2">{`${nft?.creator?.firstName} ${nft?.creator?.lastName}`}</Typography>
                      </Box>                                    
                      </Stack>                      
                    </Stack>

                  </Grid>

                  <Grid item md={3} xs={12} pl={{md: 3, xs: 0}} mt={{md: 0, xs: 1}}>
                    <Stack alignItems='center' direction="row">
                      <Box sx={{border: '1px solid #333'}} p="5px 15px">
                        <Typography variant="subtitle1">Purchased</Typography>
                      </Box>
                      <Social><SvgIcon sx={{width: '16px'}}>{shareSVG()}</SvgIcon></Social>
                    </Stack>
                    <Stack p={2} mt={2} sx={{border: '1px solid #999'}} alignItems='flex-start' spacing={2}>
                      <Stack alignItems="center" direction="row" ml={4}>
                        <Avatar
                          alt="Remy Sharp"
                          src={`${GC_BUCKET_URL}${user?.photo}`}
                          sx={{
                            width: 40,
                            height: 40,
                          }}                        
                        />  
                        <Box ml={1}>
                          <Typography variant="body1">Owner</Typography>
                          <Typography variant="subtitle1" fontWeight='600'>{`${user?.firstName} ${user?.lastName}`}</Typography>
                        </Box>                                    
                      </Stack>

                      <Stack alignItems="center" direction="row" ml={4}>
                        <Avatar
                          alt="Remy Sharp"                          
                          sx={{
                            width: 40,
                            height: 40,
                            background: '#2A3538' 
                          }}                        
                        ><SvgIcon>{calendarSVG()}</SvgIcon></Avatar> 

                        <Box ml={1}>
                          <Typography variant="subtitle1">Date</Typography>
                          <Typography variant="body2">{`${moment(nft?.createdAt).format('LLL')}`}</Typography>
                        </Box>                                    
                      </Stack>

                      <Stack alignItems="center" direction="row" ml={4}>
                        <Avatar                                                    
                          sx={{
                            width: 40,
                            height: 40,
                            background: '#2A3538' 
                          }}                        
                          ><SvgIcon>{numberSignSVG()}</SvgIcon></Avatar> 
                        <Box ml={1}>
                          <Typography variant="subtitle1">Contract Address</Typography>
                          <Stack alignItems='center' direction='row'>
                            <Typography variant="body2">{`${nft?.contractAddress?.slice(0, 4)}...${nft?.contractAddress?.slice(-4)}}`}</Typography>                            
                            <Button sx={{color: '#999'}} onClick={_ => handleCopy(nft.contractAddress)}><ContentCopy/></Button>
                          </Stack>
                        </Box>                                    
                      </Stack>
                    </Stack>
                  </Grid>                    
                </Grid>

                <Box py={3}>
                  <Typography variant="h5">Other NFT's from {`${nft?.creator?.firstName} ${nft?.creator?.lastName}` }</Typography> 
                  <Grid container spacing={{ xs: 1.5, md: 5 }} pt={2} justifyContent={"start"}>
                    {saleNfts.map((nft, i) => (
                      <Grid key={i} item md={3} sm={4} xs={6}>
                        <NftItemCard nft={nft}/>
                      </Grid>
                    ))}

                    {saleNfts?.length === 0 && (
                      <Typography variant="h2" my={6}>
                        No Nfts Yet !
                      </Typography>
                    )}
                  </Grid>

                  <Stack direction="row" justifyContent="center" my={5}>
                    {hasMore && <LoadMore onClick={handleLoadMore}>Load More</LoadMore>}
                  </Stack>
                </Box>
                
              </Container>
            </Box>
        </>
      </Box>
    </>
  );
};

export default ViewContent;
